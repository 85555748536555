import { useObtenerDatos, useProcesos } from '../../../hooks';

import { Box, Grid2, Paper, Typography } from '@mui/material';
import Composicion from './panel_control/Composicion';
import Ranking from './panel_control/Ranking';

import SelectorProcesos from './comun/SelectorProceso';
import SelectorGrupoModelo from './comun/SelectorGrupoModelo';
import Evolucion from './panel_control/Evolucion';
import Indicadores from './panel_control/Indicadores';
import InfoBlock from './comun/InfoBlock';

import * as api from '../../../api';

const estados = ['CERRADO'];

function PanelControl() {
  const { grupoModelo, gruposModelos, proceso } = useProcesos({ estados });

  let errorProceso = { errId: 1, razon: 'Cargando Panel de Control ...', tipo: 'cargando' };

  const onCambioProceso = (codigo) => {
    proceso.setProceso(grupoModelo.procesos.find((p) => p.codigo === codigo));
  };

  const onCambioGrupo = (grupo) => {
    proceso.setProceso(null);
    grupoModelo.setGrupoModelo(grupo);
  };

  const { [`evolucion_${proceso?.valor?.codigo}`]: evolucion } = useObtenerDatos({
    nombre: `evolucion_${proceso?.valor?.codigo}`,
    llamadaAPI: api.rv.obtenerEvolucion,
    parametros: { proceso: proceso?.valor?.codigo },
    condicion: !!proceso?.valor
  });
  const { [`ranking_${proceso?.valor?.codigo}`]: ranking } = useObtenerDatos({
    nombre: `ranking_${proceso?.valor?.codigo}`,
    llamadaAPI: api.rv.obtenerRanking,
    parametros: { proceso: proceso?.valor?.codigo },
    condicion: !!proceso?.valor
  });
  const { [`indicadores_${proceso?.valor?.codigo}`]: indicadores } = useObtenerDatos({
    nombre: `indicadores_${proceso?.valor?.codigo}`,
    llamadaAPI: api.rv.obtenerIndicadores,
    parametros: { proceso: proceso?.valor?.codigo },
    condicion: !!proceso?.valor
  });
  const { [`composicion_porcentajes_${proceso?.valor?.codigo}`]: porcentajes } = useObtenerDatos({
    nombre: `composicion_porcentajes_${proceso?.valor?.codigo}`,
    llamadaAPI: api.rv.obtenerComposicionPorc,
    parametros: { proceso: proceso?.valor?.codigo },
    condicion: !!proceso?.valor
  });
  const { [`composicion_absolutos_${proceso?.valor?.codigo}`]: absolutos } = useObtenerDatos({
    nombre: `composicion_absolutos_${proceso?.valor?.codigo}`,
    llamadaAPI: api.rv.obtenerComposicionAbs,
    parametros: { proceso: proceso?.valor?.codigo },
    condicion: !!proceso?.valor
  });

  if (!!grupoModelo?.valor) {
    if (grupoModelo?.procesos?.length === 0) {
      errorProceso = {
        errId: -1,
        razon: 'No hay procesos cerrados disponibles',
        tipo: 'info'
      };
    }

    if (!!proceso?.valor) {
      if (proceso.valor.estado !== 'CERRADO') {
        errorProceso = { errId: 10, razon: `Proceso no se ha cerrado aún.`, tipo: 'info' };
      } else if (!evolucion || !ranking || !indicadores || !porcentajes || !absolutos) {
        errorProceso = { errId: 1, razon: 'Cargando Panel de Control ...', tipo: 'cargando' };
      } else {
        errorProceso = { errId: 0 };
      }
    }
  }

  return (
    <>
      <Box sx={{ pt: '20px', position: 'relative' }}>
        <Typography sx={{ ...css.h1 }} component={'span'}>
          Panel de control
          {gruposModelos?.length > 1 && (
            <SelectorGrupoModelo
              onCambioGrupo={onCambioGrupo}
              grupo={grupoModelo.valor}
              grupos={gruposModelos}
            />
          )}
          {grupoModelo?.procesos?.length > 0 && (
            <SelectorProcesos
              onCambioProceso={onCambioProceso}
              proceso={proceso?.valor?.codigo}
              procesos={grupoModelo.procesos}
            />
          )}
        </Typography>
      </Box>
      {errorProceso.errId !== 0 ? (
        <InfoBlock tipo={errorProceso.tipo}>{errorProceso.razon}</InfoBlock>
      ) : (
        <>
          {/* TODO> Reemplazar esta ensalada de MUI Grid por CSS Grids vanilla */}
          <Grid2 container spacing={2} sx={{ mt: 0.5 }}>
            <Grid2 container size={{ sm: 12, lg: 8 }} flexDirection='row'>
              <Paper
                sx={{
                  ...css.card,
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  height: '380px',
                  width: '100%'
                }}
              >
                <Evolucion datos={evolucion} />
              </Paper>
            </Grid2>
            <Grid2 size={{ sm: 12, md: 6, lg: 4 }} container alignContent='stretch'>
              <Paper
                sx={{
                  ...css.card,
                  flexGrow: 1,
                  height: '380px',
                  '& hr:last-of-type': { display: 'none' }
                }}
              >
                <Ranking datos={ranking} />
              </Paper>
            </Grid2>
            <Grid2
              size={{ sm: 12, md: 6, lg: 12 }}
              container
              flexDirection={{ sm: 'row', md: 'column', lg: 'row' }}
            >
              <Indicadores datos={indicadores} />
            </Grid2>
          </Grid2>
          <Paper sx={{ ...css.card, my: 2 }}>
            <Composicion absLargoLabel={120} datos={{ absolutos, porcentajes }} />
          </Paper>
        </>
      )}
    </>
  );
}
const css = {
  // Usar VW para los textos en los breakpoints centrales
  card: {
    borderRadius: '16px',
    padding: '10px 18px 18px 18px',
    boxShadow: '0px 2px 11px 0px rgba(69, 81, 76, 0.14)'
  },
  h1: {
    fontSize: { xs: 24, lg: 32 },
    fontWeight: 600,
    margin: 0,
    display: 'block',
    position: 'relative'
  },
  cardHeader: {
    '& h2': {
      fontSize: { xs: 17, lg: 20 },
      fontWeight: 600,
      margin: 0,
      padding: 0
    }
  },
  sectionDesc: {
    fontSize: 14,
    fontWeight: 400
  },
  colab: {
    display: 'flex',
    alignItems: 'flex-start',
    '& span': { fontSize: 14 }
  },
  colabDatos: {
    px: 1,
    '& *': { padding: 0, margin: 0 },
    '& h5': { fontSize: '14px', fontWeight: 500 },
    '& h6': { fontSize: '12px', fontWeight: 300 }
  },
  colabRV: {
    margin: 0,
    padding: 0,
    textAlign: 'right',
    fontSize: 14,
    fontWeight: 500
  },
  peso: {},
  composicionTooltip: {
    display: 'inline-block',
    backgroundColor: '#FFF',
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
    padding: '5px 9px',
    borderRadius: '2px',
    fontSize: 'inherit',
    color: 'inherit'
  }
};

export default PanelControl;

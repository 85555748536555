import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useGuardarDatos, useObtenerDatos } from '../../../hooks';

import { Box, Button, FormControl, Grid2, TextField } from '@mui/material';
import { TTabla } from '../../thallus';

import * as state from '../../../state';
import * as api from '../../../api';

function Menus() {
  const { menus } = useObtenerDatos({
    nombre: 'menus',
    llamadaAPI: api.interno.obtenerMenus
  });

  const isMobile = useSelector(state.config.isMobile);

  const [menu, setMenu] = useState({});

  const { crearMenu } = useGuardarDatos({
    nombre: 'menu',
    llamadaAPI: api.interno.crearMenu,
    cacheActualizar: ['menus', 'menu']
  });

  const { editarMenu } = useGuardarDatos({
    nombre: 'menu',
    llamadaAPI: api.interno.editarMenu,
    cacheActualizar: ['menus', 'menu'],
    tipo: 'editar'
  });

  const handleOnChange = (e) => {
    setMenu({ ...menu, [e.currentTarget.id]: e.currentTarget.value });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (!!menu.id) {
      editarMenu({ menu });
    } else {
      crearMenu({ menu });
    }

    setMenu({});
  };

  return (
    <Grid2
      container
      direction='row'
      alignItems='stretch'
      sx={{
        width: '100%',
        display: 'grid',
        gap: 0,
        overflowY: 'scroll',
        ...(!isMobile ? { px: 3, py: 1 } : { px: 1, py: 0 })
      }}
    >
      <Grid2>
        <Box component='form' noValidate autoComplete='off' onSubmit={handleOnSubmit}>
          <Grid2
            container
            spacing={2}
            sx={{
              '& > * > *': { flexGrow: 1 }
            }}
          >
            <Grid2 container size={{ xs: 6, lg: 3 }} alignItems={'stretch'} flexDirection={'row'}>
              <FormControl margin='dense' fullWidth>
                <TextField
                  id='nombre'
                  size='small'
                  variant='standard'
                  onChange={handleOnChange}
                  label='Nombre'
                  value={menu.nombre ? menu.nombre : ''}
                />
              </FormControl>
            </Grid2>

            <Grid2 container size={{ xs: 6, lg: 3 }} alignItems={'stretch'} flexDirection={'row'}>
              <FormControl margin='dense' fullWidth>
                <TextField
                  id='ruta'
                  size='small'
                  variant='standard'
                  onChange={handleOnChange}
                  label='Ruta'
                  value={menu.ruta ? menu.ruta : ''}
                />
              </FormControl>
            </Grid2>

            <Grid2 container size={{ xs: 6, lg: 3 }} alignItems={'stretch'} flexDirection={'row'}>
              <FormControl margin='dense' fullWidth>
                <TextField
                  id='icono'
                  size='small'
                  variant='standard'
                  onChange={handleOnChange}
                  label='Ícono'
                  value={menu.icono ? menu.icono : ''}
                />
              </FormControl>
            </Grid2>

            <Grid2 container size={{ xs: 6, lg: 3 }} alignItems={'stretch'} flexDirection={'row'}>
              <FormControl margin='dense' fullWidth>
                <TextField
                  id='componente_react'
                  size='small'
                  variant='standard'
                  onChange={handleOnChange}
                  label='Componente React'
                  value={menu.componente_react ? menu.componente_react : ''}
                />
              </FormControl>
            </Grid2>
          </Grid2>

          <Button
            type='submit'
            color={menu.id ? 'secondary' : 'primary'}
            variant='contained'
            size='small'
            disableElevation
          >
            {menu.id ? 'Editar' : 'Guardar'}
          </Button>

          <Button
            color='primary-alt'
            variant='contained'
            size='small'
            disableElevation
            sx={{ ml: 1 }}
            onClick={() => setMenu({})}
          >
            Limpiar
          </Button>
        </Box>
      </Grid2>

      {!!menus && (
        <Grid2 sx={{ pt: 2 }}>
          <TTabla
            density='compact'
            selectOnClick
            columnas={[
              { field: 'nombre', headerName: 'Nombre', width: 300 },
              { field: 'ruta', headerName: 'Ruta', width: 300 },
              { field: 'icono', headerName: 'Ícono', width: 300 },
              { field: 'componente_react', headerName: 'Componente React', width: 300 }
            ]}
            filas={menus}
            onClick={(row) => {
              setMenu(row);
            }}
          />
        </Grid2>
      )}
    </Grid2>
  );
}

export default Menus;

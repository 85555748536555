/**
 * @module hooks/useAccion
 */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

/**
 * @param {object} params
 * @param {Function} params.llamadaAPI - función que se ejecutará para hacer la petición `PUT` o `POST` al
 * backend, puede ser alguna de las funciones en el directorio `api` o una función anónima
 * envolviendo una llamada con `axios`
 * @param {Function} params.onSuccess - función que se ejecuta en caso de una petición exitosa
 * @param {Function} params.onError - función que se ejecuta en caso de una petición fallida
 * @param {boolean} params.condicion - bandera que indica si `useQuery` debe ejecutarse o no
 * @param {(string | (query : import('@tanstack/react-query').Query ) => boolean )[]} params.cacheActualizar - Lista de keys de RQ que invalidar o métodos para filtrar keys de RQ por invalidar.
 * @returns {object}
 */
function useAccion({ llamadaAPI, onSuccess = null, onError = null, cacheActualizar = null }) {
  const navigate = useNavigate();

  if (!llamadaAPI) {
    throw new Error('Falta llamadaAPI');
  }

  if (cacheActualizar) {
    if (!Array.isArray(cacheActualizar)) {
      throw new Error('cacheActualizar debe ser un array');
    }

    for (const key of cacheActualizar) {
      if (!['string', 'function'].includes(typeof key)) {
        throw new Error('cacheActualizar debe ser un array de strings');
      }
    }
  }

  const qc = useQueryClient();

  // const [progress, setProgress] = useState(0);

  const { mutate, error, isError, isSuccess, isPending } = useMutation({
    mutationFn: (args) =>
      llamadaAPI({
        // token,
        ...args
      }),
    onSuccess: (data) => {
      if (onSuccess) onSuccess(data);

      return Promise.all(
        cacheActualizar.map((item) =>
          item.includes('*')
            ? qc.invalidateQueries({
                predicate: (query) => query.queryKey[0].includes(item.replace('*', ''))
              })
            : qc.invalidateQueries({ queryKey: [item] })
        )
      );
    },
    onError: (error) => {
      if (error.code === 'ERR_CANCELED') {
        navigate('/autenticacion/ingresar', { replace: true });
      }

      if (onError) onError(error);
    },
    retry: (failureCount, error) => failureCount <= 2 && error.response.status === 401
  });

  return {
    accionar: (args) => {
      mutate(args);
    },
    // progress,
    procesado: isSuccess,
    procesando: isPending,
    errores: isError && !!error && { status: error.response.status, data: error.response.data.msg }
  };
}

export default useAccion;

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useGuardarDatos, useObtenerDatos } from '../../../hooks';

import { Button, Chip, Grid2, TextField } from '@mui/material';
import { TSelectPermisos, TTabla } from '../../thallus';
import { Box } from '@mui/system';

import * as api from '../../../api';
import * as state from '../../../state';

function PermisosUsuarios() {
  const { usuariosConPermisos } = useObtenerDatos({
    nombre: 'usuariosConPermisos',
    llamadaAPI: api.autenticacion.obtenerUsuariosConPermisos
  });

  const isMobile = useSelector(state.config.isMobile);

  const [uuid, setUuid] = useState(null);
  const [permisos, setPermisos] = useState(null);

  const { editarPermisosUsuario } = useGuardarDatos({
    nombre: 'PermisosUsuario',
    llamadaAPI: api.autorizacion.asignarPermisosUsuario,
    cacheActualizar: ['usuariosConPermisos', 'permisos', 'listadoPermisos'],
    tipo: 'editar'
  });

  const handleOnSubmit = (event) => {
    event.preventDefault();

    if (uuid) {
      editarPermisosUsuario({
        usuario: uuid,
        permisos: permisos.map((p) => p.nombre.split('.'))
      });
    }
  };

  return (
    <Grid2
      container
      direction='row'
      alignItems='stretch'
      sx={{
        width: '100%',
        display: 'grid',
        gap: 0,
        overflowY: 'scroll',
        ...(!isMobile ? { px: 3, py: 1 } : { px: 1, py: 0 })
      }}
    >
      <Grid2>
        <Box component='form' noValidate autoComplete='off' sx={{ mx: 'auto' }} onSubmit={handleOnSubmit}>
          <TextField
            id='uuid'
            size='small'
            variant='standard'
            onChange={(e) => {
              setUuid(e.currentTarget.value);
            }}
            label='UUID'
            value={uuid ? uuid : ''}
          />
          <TSelectPermisos
            onChange={(e, v) => {
              setPermisos(v);
            }}
            value={permisos || []}
          />

          <Button type='submit' color='primary' size='small' variant='contained' disableElevation>
            Guardar
          </Button>

          <Button
            color='primary-alt'
            variant='contained'
            size='small'
            disableElevation
            sx={{ ml: 1 }}
            onClick={() => {
              setUuid(null);
              setPermisos(null);
            }}
          >
            Limpiar
          </Button>
        </Box>
      </Grid2>

      {usuariosConPermisos && (
        <Grid2 sx={{ pt: 2 }}>
          <TTabla
            density='compact'
            selectOnClick
            campoId='uuid'
            columnas={[
              { field: 'uuid', headerName: 'UUID', flex: 0.5 },
              { field: 'rut', headerName: 'Rut', flex: 0.5 },
              { field: 'email', headerName: 'Email', flex: 0.5 },
              {
                field: 'permisos',
                headerName: 'Permisos',
                flex: 1,
                renderCell: (params) => (
                  <div style={{ whiteSpace: 'break-spaces' }}>
                    {params.row.permisos.map((p) => (
                      <Chip
                        size='small'
                        key={p.id}
                        label={p.p.join('.') + (p.subrcrso ? `[${p.subrcrso}]` : '')}
                      />
                    ))}
                  </div>
                )
              }
            ]}
            filas={usuariosConPermisos.map(({ uuid, rut, email, permisos }) => ({
              uuid,
              rut,
              email,
              permisos
            }))}
            onClick={(row) => {
              setUuid(row.uuid);
              setPermisos([
                ...row.permisos.map((p) => ({
                  id: p.id,
                  nombre: p.p.join('.') + (p.subrcrso ? `[${p.subrcrso}]` : '')
                }))
              ]);
            }}
          />
        </Grid2>
      )}
    </Grid2>
  );
}

export default PermisosUsuarios;

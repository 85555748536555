/**
 * @module app/components/autenticacion/NuevaPassword
 */
import { Fragment } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useObtenerDatos } from '../../hooks';

import { Typography, CircularProgress, Grid2 } from '@mui/material';

import TFormularioNuevaPassword from './TFormularioNuevaPassword';
import TAuthCard from './TAuthCard';
import InfoBlock from '../servicios/rv/comun/InfoBlock';
import { TLink } from '../thallus';

import * as api from '../../api';

/**
 * Contenedor formulario generar nueva clave
 * @returns {JSX.Element}
 */
function NuevaPassword() {
  const [params] = useSearchParams();
  const token = params.get('token');

  const { cargando, error } = useObtenerDatos({
    nombre: 'verificarTokenRestablecerContraseña',
    llamadaAPI: api.autenticacion.verificarTokenRestablecerPassword,
    parametros: { datos: { token } },
    condicion: !!token,
    nofresh: true,
    usaToken: false
  });

  return (
    <Grid2 container size={{ xs: 12, md: 12 }}>
      <Grid2 size={{ xs: 12 }} container direction='column' alignItems='center' justifyContent='center'>
        <TAuthCard>
          {cargando ? (
            <CircularProgress size={18} sx={{ mr: 1, verticalAlign: 'middle' }} />
          ) : error ? (
            <InfoBlock tipo='error' variant>
              <span style={{ fontWeight: 500 }}>Enlace inválido o expirado.</span>
            </InfoBlock>
          ) : (
            <Fragment>
              <Typography textAlign='left' color='primary.S80' sx={css.h5}>
                Restablecer contraseña
              </Typography>
              <Typography textAlign='left' color='primary.S80' sx={css.h3}>
                Escribe tu nueva contraseña
              </Typography>
              <TFormularioNuevaPassword token={token} />
              <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '1rem' }}>
                <TLink to='/autenticacion/ingresar' style={{ textDecoration: 'none' }}>
                  <Typography fontSize={13} color='#5595BD'>
                    Volver al inicio
                  </Typography>
                </TLink>
              </div>
            </Fragment>
          )}
        </TAuthCard>
      </Grid2>
    </Grid2>
  );
}

// TODO : Mover a theme.js u similar
const css = {
  h3: {
    fontFamily: 'Poppins',
    fontWeight: '500',
    fontSize: '1.6rem',
    lineHeight: '36px'
  },
  h5: {
    fontFamily: 'Poppins',
    fontWeight: '300',
    fontSize: '1.1rem'
  }
};

export default NuevaPassword;

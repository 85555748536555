import { useCallback, useRef } from 'react';

function useTabNotification({ blinkInterval = 1000, indicator = '•' } = {}) {
  const intervalIdRef = useRef(null);
  const isBlinkingRef = useRef(false);
  const notificationHandledRef = useRef(false);
  const tituloOriginalRef = useRef(document.title);

  const startBlinking = useCallback(() => {
    if (isBlinkingRef.current || notificationHandledRef.current) {
      return;
    }

    let isShowingIndicator = true;

    const id = setInterval(() => {
      document.title = isShowingIndicator
        ? `${indicator} ${tituloOriginalRef.current}`
        : tituloOriginalRef.current;
      isShowingIndicator = !isShowingIndicator;
    }, blinkInterval);

    intervalIdRef.current = id;
    isBlinkingRef.current = true;
  }, [blinkInterval, indicator]);

  const stopBlinking = useCallback(() => {
    if (!isBlinkingRef.current) {
      return;
    }

    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }

    document.title = tituloOriginalRef.current;
    isBlinkingRef.current = false;
  }, []);

  const notificacionTab = useCallback(() => {
    stopBlinking();
    notificationHandledRef.current = false;

    const isWindowActive = document.hasFocus();
    const isTabVisible = document.visibilityState === 'visible';

    if (isTabVisible && isWindowActive) {
      return () => {};
    }

    if (!isTabVisible || !isWindowActive) {
      startBlinking();
    }

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        stopBlinking();
        notificationHandledRef.current = true;
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);

    const handleWindowFocus = () => {
      if (document.visibilityState === 'visible') {
        stopBlinking();
        notificationHandledRef.current = true;
      }
    };
    window.addEventListener('focus', handleWindowFocus);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('focus', handleWindowFocus);
      stopBlinking();
    };
  }, [startBlinking, stopBlinking]);

  return { notificacionTab };
}

export default useTabNotification;

import { Grid2, LinearProgress } from '@mui/material';
import Logo from '../autenticacion/Logo';

function PaginaCargando() {
  return (
    <Grid2
      container
      display='flex'
      alignItems='center'
      justifyContent='center'
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: '#e5f8ed',
        gap: 0,
        overflowY: 'scroll'
      }}
    >
      <Grid2 sx={{ width: '30%' }}>
        <Logo
          variation='marca'
          style={{
            maxHeight: '200px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingBottom: 30
          }}
        />
        <LinearProgress color='secondary' />
      </Grid2>
    </Grid2>
  );
}

export default PaginaCargando;
